import { ChevronRightIcon } from '@chakra-ui/icons';
import { HStack, Link as ChakraLink, VStack } from '@chakra-ui/react';
import React from 'react';

import { ActionFragment } from '../../../graphql-types';
import {
  WLBox,
  WLButton,
  WLLink,
  WLText,
  WLTextV2,
} from '../../design-library';
import ContentfulUtils from '../../utils/contentful.utils';
import FormModal from '../FormModal';
import ImageAdapter from '../ImageAdapter';
import { TextRenderer } from '../RichText/RichText.renderers';

interface ActionAdapterProps {
  data: ActionFragment;
}

type ButtonColor = React.ComponentProps<typeof WLButton>['colorScheme'];
type ButtonVariant = React.ComponentProps<typeof WLButton>['variant'];
type ButtonDirectionIcon = React.ComponentProps<
  typeof WLButton
>['directionalIcon'];

const renderText = (text: string | null | undefined) =>
  text ? TextRenderer(text) : '';

const ActionAdapter: React.FC<ActionAdapterProps> = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const [isOverlay, setIsOverlay] = React.useState(false);

  React.useEffect(() => {
    if (window.location.search.includes('formOverlay')) {
      setOpen(true);
    }
    return () => {};
  }, []);

  if (!data) {
    return null;
  }
  const linkedAsset = ContentfulUtils.getAssetInfo(data.linkedAsset);
  const href = data.action || linkedAsset?.url;

  if (data.variant === 'LINK') {
    // TODO Edge case - currently don't support FormModal via the LINK variant
    return (
      <WLLink to={href!} arrow width="full">
        <HStack flexGrow={1}>
          {data.image && (
            <ImageAdapter
              data={ContentfulUtils.getAssetInfo(data.image)}
              style={{
                maxHeight: '80px',
                maxWidth: '125px',
              }}
            />
          )}
          <WLBox>
            <WLText fontWeight="700">{renderText(data.label)}</WLText>
            {data.details && (
              <WLText fontSize="sm">
                {renderText(ContentfulUtils.getLongTextValue(data, 'details'))}
              </WLText>
            )}
          </WLBox>
        </HStack>
      </WLLink>
    );
  }

  if (data.variant === 'LINK_UPDATED') {
    return (
      <ChakraLink
        href={href}
        textDecoration="none"
        colorScheme={`wl-blue.sky`}
        fontWeight="bold"
        color="wl-blue.sky"
        _hover={{ color: 'white' }}
        fontSize="14px"
        style={{ marginTop: 0 }}
      >
        <WLTextV2 _hover={{ color: 'white' }} display={'inline-block'}>
          {renderText(data.label)}
        </WLTextV2>

        <ChevronRightIcon />
      </ChakraLink>
    );
  }

  if (data.variant === 'LINK_CARD') {
    return (
      <VStack
        flexGrow={1}
        padding="8px"
        border="1px solid #DBD9D7"
        borderRadius="16px"
        backgroundColor={'#FCFBFB'}
        mb="16px"
        sx={{
          [`.gatsby-image-wrapper`]: {
            maxHeight: '80px',
            maxWidth: '125px',
            marginBottom: '8px',
          },
        }}
      >
        {data.image && (
          <ImageAdapter
            data={ContentfulUtils.getAssetInfo(data.image)}
            style={{
              maxHeight: '80px',
              maxWidth: '125px',
            }}
          />
        )}
        <WLBox>
          {/* <WLText fontWeight="700">{renderText(data.label)}</WLText> */}
          {data.details && (
            <ChakraLink href={href} fontWeight="bold" color="#00549F">
              <WLText fontSize="sm">
                {renderText(ContentfulUtils.getLongTextValue(data, 'details'))}
              </WLText>
            </ChakraLink>
          )}
        </WLBox>
      </VStack>
    );
  }

  let buttonDirection: ButtonDirectionIcon;
  if (data.directionalIcon) {
    if (/BACK/.test(data.directionalIcon)) {
      buttonDirection = 'back';
    } else if (/FORWARD/.test(data.directionalIcon)) {
      buttonDirection = 'forward';
    }
  }

  let buttonVariant: ButtonVariant = 'solid';
  if (/OUTLINE/.test(data.buttonStyle || '')) {
    buttonVariant = 'outline';
  }
  let buttonColor: ButtonColor = 'blue';
  if (/RED/.test(data.buttonStyle || '')) {
    buttonColor = 'red';
  } else if (/WHITE/.test(data.buttonStyle || '')) {
    buttonColor = 'white';
  } else if (/LIGHT_BLUE/.test(data.buttonStyle || '')) {
    buttonColor = 'lightblue';
  }

  if (href) {
    return (
      <WLButton
        directionalIcon={buttonDirection}
        colorScheme={buttonColor}
        variant={buttonVariant}
        href={href}
      >
        {renderText(data.label)}
      </WLButton>
    );
  } else if (data.openModalWithForm) {
    return (
      <>
        <WLButton
          colorScheme={buttonColor}
          variant={buttonVariant}
          directionalIcon={buttonDirection}
          onClick={() => setOpen(true)}
          aria-label="Open Dialog"
        >
          {renderText(data.label)}
        </WLButton>
        <FormModal
          formRef={data.openModalWithForm}
          open={open}
          onClose={() => setOpen(false)}
        />
      </>
    );
  }
  return null;
};

export default ActionAdapter;
