import { Box, SimpleGrid, VStack } from '@chakra-ui/react';
import ActionAdapter from '../../components/ActionAdapter/index';
import { graphql, PageProps } from 'gatsby';
import React from 'react';

import midsectionImg from '/src/images/juris-trailer-2880x920.jpg';

import { JurisQuery } from '../../../graphql-types';
import { Hero } from '../../components/JurisdictionalConvention/index';
import Layout from '../../components/Layout';
import Section from '../../components/MedicareSupplement/Section/Section';
import { TextRenderer } from '../../components/RichTextV2/RichTextV2.renderers';
import { WLH3V2 } from '../../design-library';
import { WLContainer } from '../../design-library';
import ContentfulUtils from '../../utils/contentful.utils';
import Button from '../../design-library/Button';

interface JurisdictionalConventionPageProps extends PageProps {
  data: JurisQuery;
}

type Guest = {
  name: string;
  info: string;
};

type Guests = {
  guest: Guest[];
};

type Contact = {
  name: string;
  phone: string;
};

type Contacts = {
  guest: Contact[];
};

const JurisdictionalConventionPage: React.FC<
  JurisdictionalConventionPageProps
> = ({ data }) => {
  const [jurisValue, setjurisValue] = React.useState('');
  const [showPage, setShowPage] = React.useState(false);

  const jurisData = React.useMemo(() => {
    let juris;
    const { nodes } = data.allContentfulJurisdictionalConvention;
    if (!data) return null;
    nodes.every(
      (node: {
        id: string;
        jurisdiction: string | null;
        location?: string | null;
        startDatetime?: string | null;
        endDatetime?: string | null;
        guests?: string | null;
        jurisdictionAgenda?: object | null;
        recommendedAccommodations?: string | null;
        hotelLink?: string | null;
        hotelRegistrationDeadline?: string | null;
        mealTicketRegistrationDeadline?: string | null;
        // groupTourSignUpRegistrationDeadline?: string | null;
        contacts?: string | null;
      }) => {
        if (node.jurisdiction === jurisValue) {
          juris = node;
          let startDate = new Date(node.startDatetime);
          juris.startDatetime = startDate.toDateString();
          let endDate = new Date(node.endDatetime);
          juris.endDatetime = endDate.toDateString();
          if (node.hotelRegistrationDeadline) {
            let hotelDate = new Date(
              `${node.hotelRegistrationDeadline} 12:00:00`,
            );
            juris.hotelRegistrationDeadline = hotelDate.toDateString();
          }
          let mealDate = new Date(
            `${node.mealTicketRegistrationDeadline} 12:00:00`,
          );
          juris.mealTicketRegistrationDeadline = mealDate.toDateString();
          // let tourDate = new Date(`${node.groupTourSignUpRegistrationDeadline} 12:00:00`);
          // juris.groupTourSignUpRegistrationDeadline = tourDate.toDateString();
          return false;
        } else juris = null;
        return true;
      },
    );

    return juris;
  }, [data, jurisValue]);

  const parsedGuests = React.useMemo(() => {
    if (jurisData?.guests?.internal?.content) {
      return JSON.parse(jurisData?.guests?.internal?.content) as Guests;
    }
    return null;
  }, [jurisData?.guests?.internal?.content]);

  const parsedContacts = React.useMemo(() => {
    if (jurisData?.contacts?.internal?.content) {
      return JSON.parse(jurisData?.contacts?.internal?.content) as Contacts;
    }
    return null;
  }, [jurisData?.contacts?.internal?.content]);

  const handleStateOptions = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    setjurisValue(e.target.value);
    setShowPage(true);
  };

  const ref = React.useRef(
    typeof document !== `undefined` ? document.createElement('div') : null,
  );

  return (
    <>
      <Layout
        seo={{
          title: 'WoodmenLife Jurisdictional Conventions',
          description: ``,
          slug: '/jurisdictional-conventions/',
          keywords: `Jurisdictional Conventions`,
        }}
        currentPath={''}
      >
        <>
          <WLContainer width="EXTRA_WIDE" mb={0}>
            <VStack spacing={0} position="relative" w="full">
              <Hero
                showPage={showPage}
                handleStateOptions={handleStateOptions}
                stateValue={jurisValue}
              />
              {showPage && (
                <>
                  <VStack
                    spacing={14}
                    position="relative"
                    w="full"
                    ref={ref}
                    scrollMarginTop={'200px'}
                  >
                    <Section
                      heading="About Your Event"
                      copy={`The Jurisdiction and its members form a part of the system that is the foundation through which WoodmenLife's representative form of government is structured and operates.`}
                    />
                    <p style={{ textAlign: 'center', fontSize: '18px' }}>
                      As members of WoodmenLife, we share a commitment to
                      family, community and country.
                    </p>
                    {jurisData?.jurisdictionAgenda && (
                      <>
                        <p style={{ marginBottom: '56px' }}>
                          <Button
                            colorScheme="blue"
                            onClick={() => {
                              location.href = `${jurisData?.jurisdictionAgenda.file.url}`;
                            }}
                            type="button"
                          >
                            Download Agenda PDF
                          </Button>
                        </p>
                      </>
                    )}
                    {(jurisData?.jurisdiction ||
                      jurisData?.startDatetime ||
                      jurisData?.location.location) && (
                      <Box
                        display="flex"
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        textAlign={'center'}
                        w={'100%'}
                        h={'auto'}
                        padding={{
                          base: '40px 16px 0px 16px',
                          lg: '56px 40px 0px 40px',
                        }}
                        bg={`#B5D5F4`}
                        sx={{ b: { color: 'wl-blue.navy' } }}
                      >
                        <WLH3V2>Date & Location</WLH3V2>
                        <p style={{ marginBottom: '56px' }}>
                          {jurisData?.jurisdiction && (
                            <>
                              <b>Jurisdiction:</b> {jurisData?.jurisdiction}
                              <br />
                            </>
                          )}
                          {jurisData?.startDatetime && (
                            <>
                              <b>Date of Event:</b> {jurisData?.startDatetime} -{' '}
                              {jurisData?.endDatetime}
                              <br />
                            </>
                          )}
                          {jurisData?.location.location && (
                            <>
                              <b>Location: </b>
                              {jurisData?.location.location}
                            </>
                          )}
                        </p>
                      </Box>
                    )}
                    <SimpleGrid
                      columns={{ base: 1, md: 2, lg: 2 }}
                      spacing={8}
                      style={{ textAlign: 'center' }}
                    >
                      <Box sx={{ b: { color: 'wl-blue.navy' } }}>
                        {(jurisData?.hotelRegistrationDeadline ||
                          jurisData?.mealTicketRegistrationDeadline ||
                          jurisData?.groupTourSignUpRegistrationDeadline) && (
                          <>
                            <WLH3V2>Registration Deadlines</WLH3V2>
                            <p style={{ marginBottom: '56px' }}>
                              {jurisData?.hotelRegistrationDeadline && (
                                <>
                                  <b>Hotel:</b>{' '}
                                  {jurisData?.hotelRegistrationDeadline}
                                  <br />
                                </>
                              )}
                              {jurisData?.mealTicketRegistrationDeadline && (
                                <>
                                  <b>Meal Tickets:</b>{' '}
                                  {jurisData?.mealTicketRegistrationDeadline}
                                  <br />
                                </>
                              )}
                              {jurisData.groupTourSignUpRegistrationDeadline && (
                                <>
                                  <b>Group Tour Sign Up:</b>{' '}
                                  {
                                    jurisData?.groupTourSignUpRegistrationDeadline
                                  }
                                </>
                              )}
                            </p>
                          </>
                        )}
                        {parsedGuests && (
                          <>
                            <WLH3V2>Special Guests</WLH3V2>
                            <Box sx={{ b: { color: 'wl-blue.navy' } }}>
                              <p style={{ marginBottom: '56px' }}>
                                {parsedGuests.guests.map((g) => {
                                  return (
                                    <div>
                                      <b>{g.name}</b>
                                      <br />
                                      {g.info && g.info}
                                      <br />
                                      <br />
                                    </div>
                                  );
                                })}
                              </p>
                            </Box>
                          </>
                        )}
                      </Box>
                      <Box sx={{ b: { color: 'wl-blue.navy' } }}>
                        {jurisData?.recommendedAccommodations &&
                          jurisData?.recommendedAccommodations
                            ?.recommendedAccommodations && (
                            <>
                              <WLH3V2>Recommended Accommodations</WLH3V2>
                              <p style={{ marginBottom: '56px' }}>
                                If you haven't already made your hotel
                                reservations, contact the hotel below to do so.{' '}
                                <br />
                                <br />
                                <p>
                                  {TextRenderer(
                                    ContentfulUtils.getLongTextValue(
                                      jurisData!,
                                      'recommendedAccommodations',
                                    ),
                                  )}
                                </p>
                                {jurisData?.hotelLink && (
                                  <>
                                    <br />
                                    <br />
                                    <ActionAdapter
                                      data={{
                                        id: 'bookYourHotelBtn',
                                        buttonStyle: 'OUTLINE',
                                        action: jurisData?.hotelLink,
                                        label: 'Book Your Hotel',
                                      }}
                                    />
                                  </>
                                )}
                              </p>
                            </>
                          )}
                        {parsedContacts && (
                          <>
                            <WLH3V2>Contact</WLH3V2>
                            <Box>
                              <p style={{ marginBottom: '56px' }}>
                                {parsedContacts.contacts.map((c) => {
                                  return (
                                    <div>
                                      <b>{c.name} </b>
                                      <a href={`tel:${c.phone}`}>{c.phone}</a>
                                      <br />
                                    </div>
                                  );
                                })}
                              </p>
                            </Box>
                          </>
                        )}
                      </Box>
                    </SimpleGrid>
                  </VStack>
                  <div style={{ width: '100%' }}>
                    <img src={midsectionImg} width="100%" />
                  </div>
                </>
              )}
            </VStack>
          </WLContainer>
        </>
      </Layout>
    </>
  );
};

export const query = graphql`
  query jurisData {
    allContentfulJurisdictionalConvention {
      nodes {
        jurisdiction
        location {
          location
        }
        startDatetime
        endDatetime
        guests {
          internal {
            content
          }
        }
        jurisdictionAgenda {
          ...BasicAsset
        }
        recommendedAccommodations {
          recommendedAccommodations
        }
        hotelLink
        hotelRegistrationDeadline
        mealTicketRegistrationDeadline
        # groupTourSignUpRegistrationDeadline
        contacts {
          internal {
            content
          }
        }
      }
    }
  }
`;

export default JurisdictionalConventionPage;
